import { ListItemChangeEventDetail } from '@wppopen/components-library'
import { WppListItemCustomEvent } from '@wppopen/components-library/dist/types/components'
import {
  WppAvatar,
  WppTypography,
  WppDivider,
  WppAvatarGroup,
  WppListItem,
  // WppTag,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppTooltip,
} from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import { Fragment, ReactNode, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

import { useDeleteRfi } from 'api/mutations/rfis/useDeleteRfi'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { queryClient } from 'app/Root'
import { navigation } from 'components/SidebarCmp'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import { CreatedOrUpdatedBy, RfiAgency, RfiMember } from 'types/rfis/rfi'
import { useGetMarketsByIds } from 'utils/projectUtils'

import style from './home.module.scss'
import { trackAnalytics, ANALYTICS_EVENTS } from '../../utils/analytics'

export default function ProjectPreviewCard({
  projectName,
  agencies,
  pitchTypeId,
  updatedAt,
  createdAt,
  createdBy,
  marketIds,
  projectMembers,
  id,
  rfiSummary,
  clientName,
}: Readonly<{
  projectName: string
  activeStatus: number
  clientName: string
  pitchTypeId: string
  updatedAt: string
  createdAt: string
  createdBy: CreatedOrUpdatedBy
  isCompleted: boolean
  id: string
  agencies: RfiAgency[]
  projectMembers: RfiMember[]
  marketIds: string[]
  rfiSummary: string
}>) {
  const navigate = useNavigate()
  const { data: pitchTypes } = usePitchTypes()
  const { setState } = useProjectContext()
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [listWidth, setListWidth] = useState<number>(0)
  const { mutateAsync: deleteProject } = useDeleteRfi()
  const toast = useToast()
  const titleRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<LinkProps | null>(null)
  const [truncate, setTruncate] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const rightSidePadding = 40

  const pitchType = pitchTypes?.find(p => p.id === pitchTypeId)?.typeDescription
  const markets = useGetMarketsByIds(marketIds)

  const agenciesString =
    agencies?.reduce((accum, agency, index, arr) => {
      const isLastOrFirstOfOnly1 = index === arr.length - 1 || (index === 0 && arr.length === 1)
      accum += `${agency?.name}${!isLastOrFirstOfOnly1 ? ', ' : ''}` || ''
      return accum
    }, '') || ''

  const handleEditProjectClick = (e: SyntheticEvent | WppListItemCustomEvent<ListItemChangeEventDetail>) => {
    if (
      (e?.target as HTMLElement)?.dataset?.menu ||
      (e?.target as HTMLElement).textContent?.toLowerCase()?.includes('delete') // TODO translation key
    ) {
      return
    }
    e.preventDefault()
    setState(prev => ({
      ...prev,
      projectId: id,
      rfiSummary,
    }))

    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.PROJECT_DETAILS_PAGE_VIEW,
    })

    navigate(`${navigation.projectDetails}/${id}`)
  }

  const handleDeleteProjectClick = async (id: string) => {
    try {
      await deleteProject(id)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.RFIS] })
      toast.showToast({ message: 'Project deleted successfully', type: 'success' })
    } catch (e) {
      toast.showToast({ message: 'Error deleting project', type: 'error' })
    }
  }

  const handleContainerWidth = useCallback(() => {
    if (listContainerRef.current) {
      setListWidth(listContainerRef.current.offsetWidth)
    }
  }, [])

  const handleTruncate = useCallback(() => {
    // setTruncate(true)
    setContainerWidth((containerRef.current as HTMLElement | null)?.clientWidth || 0)

    // get the width of the titleRef.current
    const titleWidth = (titleRef.current as HTMLElement | null)?.clientWidth || 0
    // get the width of the containerRef.current
    const containerWidth = (containerRef.current as HTMLElement | null)?.clientWidth || 0

    if (titleWidth > containerWidth - rightSidePadding) {
      setTruncate(true)
    }
  }, [])

  useEffect(() => {
    if (titleRef?.current && containerRef?.current) {
      handleTruncate()
    }
    window.addEventListener('resize', handleTruncate)
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [containerWidth, handleTruncate])

  useEffect(() => {
    handleContainerWidth()
    window.addEventListener('resize', handleContainerWidth)
    return () => {
      window.removeEventListener('resize', handleContainerWidth)
    }
  }, [handleContainerWidth])

  const Component = truncate
    ? ({ children }: { children: ReactNode }) => <WppTooltip text={projectName}>{children}</WppTooltip>
    : Fragment

  return (
    <div
      className="w-[32%] flex flex-col gap-4 rounded-8 p-4 bg-[#FFFFFF] hover:cursor-pointer hover:bg-[#e9eaeb] hover:shadow transition-all duration-200 ease-in-out"
      onClick={handleEditProjectClick}
    >
      <div ref={containerRef as React.RefObject<HTMLDivElement>} className="flex flex-row justify-between relative">
        <div ref={titleRef}>
          <Component>
            <WppTypography
              className="whitespace-nowrap truncate"
              type="xl-heading"
              style={{ width: `${containerWidth - rightSidePadding}px` }}
            >
              {projectName}
            </WppTypography>
          </Component>
        </div>
        <WppMenuContext className="w-1/6 absolute top-0 right-0 -mr-11" data-menu>
          <WppActionButton className="ml-auto" slot="trigger-element" data-menu>
            <WppIconMore direction="horizontal" data-menu />
          </WppActionButton>
          <div>
            <WppListItem onWppChangeListItem={handleEditProjectClick}>
              <p slot="label">Edit</p>
            </WppListItem>
            <WppListItem
              onWppChangeListItem={e => {
                e.stopPropagation() // not working
                handleDeleteProjectClick(id)
              }}
            >
              <p slot="label">Delete</p>
            </WppListItem>
          </div>
        </WppMenuContext>
      </div>
      <div className="mt-auto" ref={listContainerRef}>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Last saved: {new Date(updatedAt || createdAt).toLocaleString()}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Agencies: {agenciesString}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Client: {clientName}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Market: {markets}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">Pitch Type: {pitchType}</p>
        </WppListItem>
        <WppListItem selectable={false} className={style.listItem} style={{ width: `${listWidth - 16}px` }}>
          <p slot="label">
            Created By: <WppAvatar src={createdBy?.img ?? ''} name={createdBy?.name || ''} /> {createdBy?.name}
          </p>
        </WppListItem>
        <WppDivider className="mt-2 mb-2" />
        <div className="flex items-end">
          {projectMembers && (
            <WppAvatarGroup
              className="pt-2"
              maxAvatarsToDisplay={3}
              size="xs"
              withTooltip
              avatars={projectMembers?.map(member => ({
                name: member.memberDetail.name,
                src: member.memberDetail.img ?? '',
              }))}
            />
          )}
          {/* {Boolean(activeStatus) && (
            <WppTag className="ml-auto" variant="positive" label={activeStatus === 1 ? 'Done' : 'In Progress'} />
          )} */}
        </div>
      </div>
    </div>
  )
}
