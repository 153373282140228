import { WppActionButton, WppButton, WppIconAdd, WppIconGear } from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'

import { navigation } from 'components/SidebarCmp'

import { ProjectsFilterGroupProps } from './types'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

export const ProjectsFilterGroup = ({ navigate, agencyPermission }: ProjectsFilterGroupProps) => {
  const handleNewProjectClick = () => {
    navigate(navigation.newProject)
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: ANALYTICS_EVENTS.NEW_PROJECT_BUTTON_CLICK_ACTION,
    })
  }

  return (
    <>
      <div className="flex gap-4 w-1/2">
        {/* <WppSearch size="s" placeholder="Search" aria-label="Search projects" id="projectSearch" />
        <WppSelect
          size="s"
          onWppChange={handleChange}
          required={false}
          placeholder="Select Agency"
          id="agency-select"
          withSearch
          showSelectAllText
          // type="multiple"
          aria-label="Select an agency from the list"
          className="min-w-[160px]"
        >
          {agencies?.length > 1 &&
            agencies?.map(agency => {
              return (
                <WppListItem key={agency?.id} value={agency.id}>
                  <p slot="label">{agency.name}</p>
                </WppListItem>
              )
            })}
        </WppSelect>
        <WppFilterButton name="Filters">Filters</WppFilterButton> */}
      </div>

      <div className="flex gap-4 ml-auto">
        {/* <WppSortButton name="Sorts">Sort</WppSortButton> */}

        {agencyPermission && (
          <WppActionButton variant="secondary" onClick={() => navigate(navigation.agencyDashboard)}>
            <WppIconGear slot="icon-start" />
          </WppActionButton>
        )}
        <WppButton variant="primary" onClick={() => handleNewProjectClick()} size="s">
          <WppIconAdd slot="icon-start" />
          New Project
        </WppButton>
      </div>
    </>
  )
}
