import {
  RichtextChangeEventDetail,
  RichtextSelectionChangeEventDetail,
  RichtextUploadRequestEventDetail,
} from '@wppopen/components-library'
import { WppRichtextCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppButton, WppIconDownload, WppRichtext, WppTypography } from '@wppopen/components-library-react'
import { AnalyticsActionType } from '@wppopen/core'
import DOMPurify from 'dompurify'
import { jsPDF } from 'jspdf'
import { micromark } from 'micromark'
import PptxGenJS from 'pptxgenjs'
import { useCallback, useEffect, useState } from 'react'

import './TextEditor.css'

import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { htmlTextFormatter } from 'utils/textUtils'

import { RichTextPageProps } from './types'
import { pixelsToInches, pixelsToPoints, setImageAttributesFromDomNode, updateImageAttributesInString } from './utils'
import { wppopenDataImage } from './wpp-open-data-image'
import { ANALYTICS_EVENTS, trackAnalytics } from '../../utils/analytics'

const modules = JSON.stringify({
  toolbar: {
    aliases: {
      // Add image, video and attachments buttons to the embed section of toolbar
      embed: ['link', 'image', 'video', 'attachment'],
    },
  },
  // Enable custom upload handler for image, video and attachment
  imageUpload: true,
  videoUpload: true,
  attachmentUpload: true,
})

function upload(file: File): Promise<string> {
  return new Promise(resolve => {
    const delay = Math.floor(Math.random() * 10000)

    setTimeout(() => resolve(URL.createObjectURL(file)), delay)
  })
}

export const RichTextPage = ({ questions, rfiData }: RichTextPageProps) => {
  const { data: pitchTypes } = usePitchTypes()
  const { data: markets = [] } = useMarkets()
  const { data: regions = [] } = useRegions()

  const [value, setValue] = useState('')
  useEffect(() => {
    if (pitchTypes.length === 0 || markets.length === 0 || questions.length === 0) return

    let tmp = `
      <h2>
        <strong><span class="ql-size-m">${DOMPurify.sanitize(rfiData.projectName)}</span></strong>
      </h2>
      <p>
        <span class="ql-size-xs">${DOMPurify.sanitize('Project Name')}</span>
      </p>
   
      <h2>
        <strong><span class="ql-size-m">${DOMPurify.sanitize(rfiData.clientName)}</span></strong>
      </h2>
      <p>
        <span class="ql-size-xs">${DOMPurify.sanitize('Client name')}</span>
      </p>
       
      <h2>
       <strong><span class="ql-size-m">${DOMPurify.sanitize(pitchTypes?.find(p => p.id === rfiData.pitchTypeId)?.typeDescription ?? '')}</span></strong>
      </h2>
      <p class="ql-size-m">
      <span class="ql-size-xs">${DOMPurify.sanitize('Pitch Type')}</span>
      </p>
       
      <h2>
        <strong><span class="ql-size-m">${DOMPurify.sanitize(
          rfiData.marketIds
            ?.map(market => {
              return [...regions, ...markets]?.find(p => p.id === market)?.name ?? ''
            })
            .join(', '),
        )}</span></strong>
      </h2>
      <p>
        <span class="ql-size-xs">${DOMPurify.sanitize('Markets')}</span>
      </p>
      <br />
    `

    questions.forEach(category => {
      tmp += `
        <h2>
          <strong><span class="ql-size-m">${category.name}</span></strong>
        </h2>

        
        
        ${category.questions
          .map(question => {
            const questionText = `#### ${question.questionText}`
            const proposedAnswer =
              question?.latestChildQuestionProposedAnswer || question.proposedAnswer || 'Answer not validated/provided'
            return `${micromark(questionText)} \n ${micromark(proposedAnswer)} <br/>`
          })
          .join('')}
        `
    })

    setValue(tmp)
  }, [rfiData, pitchTypes, markets, questions, regions])

  const handleChange = useCallback((e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
    setValue(e.detail.value)
  }, [])

  const handleSelectionChange = useCallback((e: WppRichtextCustomEvent<RichtextSelectionChangeEventDetail>) => {
    console.log('wppSelectionChange', { ...e.detail.range }, e)
  }, [])

  // There is also need to enable respective embed button in toolbar (image, video and attachment)
  // and respective upload modules (imageUpload, videoUpload and attachmentUpload)
  const handleUploadRequest = useCallback((e: WppRichtextCustomEvent<RichtextUploadRequestEventDetail>) => {
    console.log('wppUploadRequest', e)

    const type = e.detail.type
    const callback = e.detail.callback
    const input = document.createElement('input')

    input.type = 'file'
    input.accept = type === 'attachment' ? '*' : `${type}/*`
    input.multiple = true
    input.onchange = () => {
      const uploadItems = Array.from(input.files!).map(file => ({
        file,
        promise: upload(file),
      }))

      callback(uploadItems)
    }
    input.click()
  }, [])

  const generatePptx = (_logoDataImage = wppopenDataImage) => {
    /**
     * Default PPTX layout aspect ratio and size in inches is
     * LAYOUT_16x9	10 x 5.625 inches
     * PptxGenJS uses points as the unit of measurement
     * 1 inch = 72 points
     * 1 point = 1/72 inch
     * 1 pixel = 1/96 inch
     *  ======== NB font sizes are in points ( powerpoint / pptxgenjs ) ======
     *  ======== NB dimensions and positions are in inches or percentages of number of inches ======
     */

    const pptx = new PptxGenJS()

    const fullWidthInInches = 10
    const fullHeightInInches = 5.625

    // const { width, height } = await getDataUrlImageDimensions(logoDataImage)
    const fontColor = '#000050'
    const reserveHeaderLogoHeight = 0.5
    /* Footer settings */
    const footerFontSize = pixelsToPoints(16)
    const footerHeight = pixelsToInches(50)
    const footerText = `WPP Open | ${rfiData.projectName}`
    const footerYTop = fullHeightInInches - footerHeight
    /*  */

    const h1FontSize = pixelsToPoints(48)
    const coverSlideYtop = 1
    const pitchType = `${pitchTypes?.find(p => p.id === rfiData.pitchTypeId)?.typeDescription ?? ''}`
    const theMarkets = `${rfiData?.marketIds
      ?.map(market => {
        return [...regions, ...markets]?.find(p => p.id === market)?.name ?? ''
      })
      .join(', ')}`

    pptx.theme = {
      bodyFontFace: 'Arial',
    }
    pptx.defineSlideMaster({
      title: rfiData.projectName,

      objects: [
        { image: { x: 0, y: reserveHeaderLogoHeight, w: '100%', h: '100%', path: '' } },
        {
          text: {
            text: footerText,
            options: {
              align: 'center',
              valign: 'top',
              x: 0,
              y: footerYTop,
              w: fullWidthInInches,
              fontSize: footerFontSize, // 9 points,
              h: footerHeight,
              color: fontColor,
              bold: true,
            },
          },
        },
      ],
    })

    const coverSlide = pptx.addSlide({ masterName: rfiData.projectName })
    let rows = [
      [
        { text: 'Project: ', options: { align: 'right', bold: true, color: fontColor } },
        { text: 'name', options: { align: 'left', color: fontColor } },
      ],
      [
        {
          text: 'Pitch Type: ',
          options: { align: 'right', bold: true, color: fontColor },
        },
        {
          text: pitchType,
          options: { align: 'left', color: fontColor },
        },
      ],
      [
        {
          text: 'Markets: ',
          options: { align: 'right', bold: true, color: fontColor },
        },
        {
          text: theMarkets,
          options: { align: 'left', color: fontColor },
        },
      ],
    ]
    coverSlide.addText(rfiData?.projectName, {
      y: coverSlideYtop,
      w: fullWidthInInches,
      h: pixelsToInches(h1FontSize),
      fontSize: pixelsToPoints(h1FontSize),
      align: 'center',
      bold: true,
      color: fontColor,
    })
    //@ts-ignore
    coverSlide.addTable(rows, {
      y: coverSlideYtop + (pixelsToInches(h1FontSize) + pixelsToInches(h1FontSize / 2)),
      w: fullWidthInInches - 1,
      h: 4 * (pixelsToInches(h1FontSize) / 2),
      x: 0,
      margin: [1, 1, 1, 1],
      rowH: pixelsToInches(h1FontSize / 2),
      fontSize: pixelsToPoints(h1FontSize / 2),
      align: 'left',
      color: fontColor,
      fontFace: 'Arial',
    })

    // const htmlString = generateQaSource(questions)
    const contentArray = htmlTextFormatter(value, { color: fontColor })
    const slide = pptx.addSlide({ masterName: rfiData.projectName })

    slide.addTable([...contentArray], {
      autoPage: true,
      autoPageHeaderRows: 1,
      h: 3.5,
      y: reserveHeaderLogoHeight,
    })

    const endSlide = pptx.addSlide({ masterName: rfiData.projectName })
    endSlide.addText('End of the presentation', { x: 0, y: 0, w: '100%', h: '100%', align: 'center' })

    pptx.writeFile({ fileName: `${rfiData.projectName}.pptx` })
  }

  const generatePDF = () => {
    let pdf = new jsPDF('p', 'pt', 'a4')
    const html = document.querySelector('.ql-container.ql-wpp .ql-editor')

    const imgAttributes = setImageAttributesFromDomNode(html as HTMLElement)
    const htmlString = updateImageAttributesInString(value, imgAttributes)

    const wrappedValue = `<div style="width: 500px; position: relative" data-p>${htmlString}</div>`
    const Yoffset = 50
    // Find all image elements in the HTML string
    const images = wrappedValue.match(/<img[^>]+>/g)

    if (images) {
      // Loop through each image element and add it to the PDF document
      images.forEach((image, index) => {
        // Extract the image URL and dimensions
        const imageUrlMatch = /src="([^"]+)"/.exec(image)
        const imageUrl = imageUrlMatch ? imageUrlMatch[1] : ''
        const { width, height } = pdf.getImageProperties(imageUrl)
        // Extract the top and left attributes of the image
        const top = imgAttributes[index].top ?? 0

        // Add image to the PDF document
        pdf.addImage(imageUrl, 'JPG', 50, top + Yoffset, width, height)
      })
    }

    pdf.html(wrappedValue, {
      callback: function (pdf) {
        pdf.save(`${rfiData.projectName}.pdf`)
      },
      autoPaging: 'text',
      margin: 50,
    })
  }

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.AI_DRAFT_RESPONSE_PAGE_VIEW,
    })
  }, [])

  const handleExport = (format: 'pptx' | 'pdf') => () => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: ANALYTICS_EVENTS.PROJECT_EXPORT,
        params: [
          {
            key: 'format',
            value: format,
          },
        ],
      },
    })

    if (format === 'pptx') generatePptx()
    if (format === 'pdf') generatePDF()
  }

  return (
    <>
      <div className="flex">
        <WppTypography type="xl-heading">Ai-Generated Draft Response Preview</WppTypography>
        <WppButton className="ml-auto" size="m" onClick={handleExport('pptx')} variant="secondary">
          <WppIconDownload slot="icon-start" />
          Export (.PPTX)
        </WppButton>
        <WppButton size="m" onClick={handleExport('pdf')} className="ml-4">
          <WppIconDownload slot="icon-start" />
          Export (.PDF)
        </WppButton>
      </div>
      <WppRichtext
        name="content"
        value={value}
        modules={modules}
        onWppChange={handleChange}
        onWppSelectionChange={handleSelectionChange}
        onWppUploadRequest={handleUploadRequest}
        required
        className="mt-10"
      />

      <div className="mt-6 flex justify-end">
        <WppButton className="ml-auto" size="m" onClick={() => generatePptx()} variant="secondary">
          <WppIconDownload slot="icon-start" />
          Export (.PPTX)
        </WppButton>
        <WppButton size="m" onClick={() => generatePDF()} className="ml-4">
          <WppIconDownload slot="icon-start" />
          Export (.PDF)
        </WppButton>
      </div>
    </>
  )
}
